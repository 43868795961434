import DropDown from '../../../atoms/DropDown';
import { FlagIcon } from '../../../atoms/FlagIcon';
import { useTranslation } from 'react-i18next';

const languageList = [
  {
    isDisplay: true,
    lang: 'Bahasa Indonesia',
    langCode: 'id-ID',
    order: 1,
  },
  {
    isDisplay: true,
    lang: 'English',
    langCode: 'en-GB',
    order: 2,
  },
  {
    isDisplay: true,
    lang: 'Español',
    langCode: 'es-ES',
    order: 3,
  },
  {
    isDisplay: true,
    lang: 'Français',
    langCode: 'fr-FR',
    order: 4,
  },
  {
    isDisplay: false,
    lang: 'Italiano',
    langCode: 'it-IT',
    order: 5,
  },
  {
    isDisplay: false,
    lang: 'Malay',
    langCode: 'ms-MY',
    order: 6,
  },
  {
    isDisplay: true,
    lang: 'Português',
    langCode: 'pt-PT',
    order: 7,
  },
  {
    isDisplay: false,
    lang: '中文',
    langCode: 'zh-CN',
    order: 8,
  },
  {
    isDisplay: false,
    lang: 'हिन्दी',
    langCode: 'hi-IN',
    order: 9,
  },
  {
    isDisplay: true,
    lang: '日本語',
    langCode: 'ja-JP',
    order: 10,
  },
  {
    isDisplay: true,
    lang: '한국어',
    langCode: 'ko-KR',
    order: 11,
  },
  {
    isDisplay: true,
    lang: 'русский',
    langCode: 'ru-RU',
    order: 12,
  },
  {
    isDisplay: true,
    lang: 'ภาษาไทย',
    langCode: 'th-TH',
    order: 13,
  },
  {
    isDisplay: true,
    lang: 'Türkçe',
    langCode: 'tr-TR',
    order: 14,
  },
  {
    isDisplay: true,
    lang: 'tiếng Việt',
    langCode: 'vi-VN',
    order: 15,
  },
];

export const SidebarLanguage = () => {
  const { t, i18n } = useTranslation();

  const onChangeLanguage = (lang) => {
    if (lang) {
      i18n.changeLanguage(lang);
    }
  };
  return (
    <>
      <DropDown
        mainMenuClassName="flex items-center h-auto p-2 w-full bg-body-color-secondary hover:bg-navbar-active-color-primary hover:opacity-90 rounded-none"
        mainMenu={
          <>
            <div className="flex w-full space-x-4">
              <div className="w-6 h-6 aspect-square rounded-full overflow-hidden flex justify-center items-center">
                <FlagIcon flagName={i18n.language} />
              </div>
              <p>{t('univ.language')}</p>
            </div>
          </>
        }
        dropdownMenuClassName="h-auto p-2 w-full bg-body-color-secondary rounded-none"
        dropdownMenu={
          <>
            {languageList
              ?.sort((a, b) => a?.order - b?.order)
              .map((lang) => (
                <div
                  key={lang.langCode}
                  className="flex w-full space-x-4 py-1 hover:bg-navbar-active-color-primary hover:opacity-90 cursor-pointer"
                  onClick={() => onChangeLanguage(lang.langCode)}
                >
                  <div className="w-6 h-6 aspect-square rounded-full overflow-hidden flex justify-center items-center">
                    <FlagIcon flagName={lang.langCode} />
                  </div>
                  <p>{lang.lang}</p>
                </div>
              ))}
          </>
        }
      />
    </>
  );
};
