/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

const SlideModalComponent = ({ isOpen, onClose, title = 'Slide', slideMode = 'toRight', children }) => {
  const onCloseHandler = () => {
    if (onClose) {
      onClose();
    }
  };

  return (
    <React.Fragment>
      <div
        className={`
          fixed inset-0 w-full min-h-screen flex items-center justify-center z-100 overflow-y-auto
          transform transition-transform duration-300 ease-in-out
          ${slideMode === 'toRight' ? (isOpen ? 'translate-x-0' : '-translate-x-full') : ''}
          ${slideMode === 'toLeft' ? (isOpen ? 'translate-x-0' : 'translate-x-full') : ''}
          ${slideMode === 'toBottom' ? (isOpen ? 'translate-y-0' : '-translate-y-full') : ''}
          ${slideMode === 'toTop' ? (isOpen ? 'translate-y-0' : 'translate-y-full') : ''}
        `}
      >
        {/* <div className="absolute inset-0 bg-black opacity-50" onClick={onClose}></div> */}
        <div className="relative w-full h-screen bg-body-color-primary overflow-y-auto no-scrollbar space-y-4">
          <div className="flex justify-between items-center p-4 text-text-color-primary bg-body-color-secondary">
            <h3 className="text-lg font-semibold">{title}</h3>
            <CloseOutlinedIcon className="cursor-pointer" onClick={onCloseHandler} />
          </div>
          <div className="px-4 pb-4">{isOpen && <React.Fragment>{children}</React.Fragment>}</div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SlideModalComponent;
