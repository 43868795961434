import axios from 'axios';
import { handleApiError } from '../../utils/errorHandler';

const apiInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASEURL,
  headers: {
    'Content-Type': 'application/json',
  },
});

apiInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      const errorMessage = handleApiError(error.response.data);
      console.log('errorMessage : ', errorMessage);
      return Promise.reject(errorMessage);
    }
    return Promise.reject(error.message);
  }
);

const api = async ({ method, url, data, headers, ...options }) => {
  const response = await apiInstance({
    method,
    url,
    data,
    headers: {
      ...apiInstance.defaults.headers,
      ...headers,
    },
    ...options,
  });
  return response.data;
};

export default api;

//

// import axios from 'axios';
// import { handleApiError } from '../../utils/errorHandler';

// const apiInstance = axios.create({
//   baseURL: process.env.REACT_APP_API_BASEURL,
//   headers: {
//     'Content-Type': 'application/json',
//   },
// });

// // Token management
// function getToken() {
//   return localStorage.getItem('userToken'); // Assume it's stored here
// }

// // Request interceptor to add token if `auth` config is true
// apiInstance.interceptors.request.use(
//   (config) => {
//     console.log('Auth config:', config.auth); // Debug output
//     if (config.auth === 'user') {
//       const token = getToken();
//       console.log('User Token:', token); // More debug output
//       if (token) {
//         config.headers['Authorization'] = `Bearer ${token}`;
//       }
//     } else if (config.auth === 'telegram' && config.telegramAuth) {
//       console.log('Telegram Auth:', config.telegramAuth);
//       config.headers['Authorization'] = `Bearer ${config.telegramAuth}`;
//     }

//     return config;
//   },
//   (error) => Promise.reject(error)
// );

// apiInstance.interceptors.response.use(
//   (response) => response,
//   (error) => {
//     if (error.response) {
//       const errorMessage = handleApiError(error.response.data);
//       console.error('Error Message:', errorMessage); // Debug output
//       return Promise.reject(errorMessage);
//     }
//     return Promise.reject(error.message);
//   }
// );

// const api = async ({ method, url, data, headers, auth, ...options }) => {
//   console.log(headers);
//   const response = await apiInstance({
//     method,
//     url,
//     data,
//     headers: {
//       ...apiInstance.defaults.headers,
//       ...headers,
//     },
//     auth, // Pass along the auth option to the request config
//     ...options,
//   });
//   return response.data;
// };

// export default api;
