/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { API, graphqlOperation } from 'aws-amplify';

export const useAppSyncSubscription = ({
  query,
  variables,
  authToken,
  callback,
  dependencies = [],
  shouldSubscribe = () => true, // Default to always true if not provided
}) => {
  useEffect(() => {
    let subscription;

    if (shouldSubscribe()) {
      // Using the provided function to decide
      // const options = {
      //   headers: {
      //     Authorization: authToken,
      //   },
      // };

      subscription = API.graphql(graphqlOperation(query, variables, authToken)).subscribe({
        next: ({ value }) => {
          if (callback) callback(value);
        },
      });
    }

    // Cleanup on unmount
    return () => {
      if (subscription) subscription.unsubscribe();
    };
  }, dependencies);
};
