/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo } from 'react';
import useAuthStore from '../../../../../stores/useAuthStore.store';
// import SearchComponent from '../../atoms/SearchComponent';
import useApiRequest from '../../../../../hooks/useApiRequest.hook';
import walletUtils from '../../../../../utils/wallet.utils';
import SlideModalComponent from '../../../../atoms/SlideModalComponent';
import useWalletIconStore from '../../stores/useWalletIconStore.store';
import { useTranslation } from 'react-i18next';
import { Loading } from '../../../../atoms/Loading';

const WalletProfileModal = () => {
  const { t } = useTranslation();
  const { data, loading, post } = useApiRequest('/changeActiveWallet2');
  const { walletBalance, token, setActiveWallet, activeWalletBalance } = useAuthStore();
  const { openWalletModal, setCloseWalletModal } = useWalletIconStore((state) => ({
    openWalletModal: state.openWalletModal,
    setCloseWalletModal: state.setCloseWalletModal,
  }));

  // const [searchWallet, setSearchWallet] = useState('');

  // useEffect(() => {}, [walletBalance]);

  useEffect(() => {
    if (data) {
      setActiveWallet(data?.data?.activeWallet);
      setCloseWalletModal();
    }
  }, [data]);

  const changeActiveWallet = (currency) => {
    if (currency) {
      post({
        options: {
          headers: {
            Authorization: token,
          },
        },
        data: {
          selectedWallet: currency,
        },
      });
    }
  };

  const WalletList = ({ title = 'currency', currencyType }) => {
    const wallet = useMemo(() => {
      return [...walletBalance.entries()].filter(([, walletData]) => walletData.currencyType === currencyType).sort();
    }, [walletBalance]);

    if (!wallet.length) {
      return null;
    }
    return (
      <React.Fragment>
        <div className="space-y-2">
          <p className="text-sm text-text-color-secondary">{title}</p>
          <div className="w-full">
            {wallet.map(([key, walletData], index) => (
              <React.Fragment key={index}>
                <div
                  className={`
                      ${walletData?.currency === activeWalletBalance?.currency && 'bg-navbar-active-color-primary'}
                      flex justify-between w-full h-8 px-2 items-center hover:cursor-pointer hover:bg-navbar-active-color-primary hover:opacity-90
                    `}
                  onClick={() => {
                    !loading && changeActiveWallet(walletData.currency);
                  }}
                >
                  <div className="flex space-x-2 w-32 h-full items-center">
                    <div className="w-7 h-7 aspect-square">
                      <img src={walletData?.currencyLogo} alt="" className="w-full h-full" loading="lazy" />
                    </div>
                    <p className="w-full text-left">{walletData?.currency}</p>
                  </div>
                  <p className="text-right">{walletUtils.formatCurrency(walletData?.totalBalance)}</p>
                </div>
              </React.Fragment>
            ))}
          </div>
        </div>
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <SlideModalComponent
        isOpen={openWalletModal}
        onClose={setCloseWalletModal}
        title={t('univ.wallet')}
        slideMode="toBottom"
      >
        {/* <div className="h-10">
            <SearchComponent value={searchWallet} onChange={setSearchWallet} />
          </div>
          <div className="flex text-center">
            <div className="flex-1">Crypto</div>
            <div className="flex-1">Fiat</div>
          </div> */}

        {loading && (
          <>
            <div className="flex justify-center items-center fixed inset-0 backdrop-blur-sm">
              <Loading></Loading>
            </div>
          </>
        )}

        <WalletList currencyType={'fiat'} title={t('univ.fiat')} />
        <WalletList currencyType={'crypto'} title={t('univ.crypto')} />
      </SlideModalComponent>
    </React.Fragment>
  );
};

export default WalletProfileModal;
