import api from '../services/api/api';

const { create } = require('zustand');

const useCountryStore = create((set) => ({
  countryList: [],
  initCountry: async (token) => {
    const data = await api({
      method: 'post',
      headers: {
        Authorization: token,
      },
      url: '/country/get',
    });

    set({ countryList: data?.data || [] });
  },
}));

export default useCountryStore;
