import { LottieAnimation } from '../LottieAnimation';
import image from './AnimatedSticker.json';

export const LoadingDot = () => {
  return (
    <div className="w-full flex items-center justify-center">
      <div className="bg-blue-500 p-2 w-2 h-2 rounded-full animate-bounce blue-circle"></div>
      <div className="bg-red-500 p-2 w-2 h-2 rounded-full animate-bounce green-circle"></div>
      <div className="bg-green-500 p-2 w-2 h-2 rounded-full animate-bounce red-circle"></div>
    </div>
  );
};

export const LoadingRing = () => {
  return (
    <div className="w-full h-full rounded-full animate-spin border-2 border-solid border-white border-t-transparent"></div>
  );
};

export const LoadingSquare = () => {
  return <div className="rounded-xl h-12 w-12 border-4 border-t-4 border-white animate-spin absolute"></div>;
};

export const LoadingInit = () => {
  return <div className="w-screen h-screen bg-body-color-primary"></div>;
};

export const Loading = () => {
  return (
    <div className="flex space-x-2">
      <p className="text-text-color-primary">Loading...</p>
      <div className="w-6 h-6 aspect-square">
        <LoadingRing />
      </div>
    </div>
  );
};

export const LoadingPage = () => {
  return (
    <div className="fixed inset-0 w-screen h-screen bg-body-color-primary">
      <div className="flex flex-col w-full h-full items-center justify-center space-y-2">
        <div className="w-20 h-20 aspect-square">
          <LottieAnimation animationData={image}></LottieAnimation>
          {/* <img src="/images/logo/logo.png" alt="w-full h-full" /> */}
        </div>
        <Loading />
      </div>
    </div>
  );
};
