import React from 'react';
import NavigationComponent from '../../atoms/NavigationComponent';
import appRoutes from '../../../core/routes/routes';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ReceiptIcon from '@mui/icons-material/Receipt';
// import RedeemIcon from '@mui/icons-material/Redeem';
import { useLocation } from 'react-router-dom';
import SlideModalComponent from '../../atoms/SlideModalComponent';
import useSidebarStore from '../../../stores/useSidebarStore.store';
import { useTranslation } from 'react-i18next';
import { SidebarLanguage } from './components/SidebarLanguage';

const Sidebar = () => {
  const { t } = useTranslation();
  const { openSidebar, setCloseSidebar } = useSidebarStore((state) => ({
    openSidebar: state.openSidebar,
    setCloseSidebar: state.setCloseSidebar,
  }));
  const location = useLocation();

  const onCloseSidebar = () => {
    if (setCloseSidebar) {
      setCloseSidebar();
    }
  };

  return (
    <React.Fragment>
      <SlideModalComponent
        isOpen={openSidebar}
        onClose={onCloseSidebar}
        title={t('univ.navigation')}
        slideMode="toLeft"
      >
        <div className="w-full">
          <NavigationComponent to={appRoutes.ACCOUNT} onClose={onCloseSidebar}>
            <div
              className={`
              ${location.pathname === appRoutes.ACCOUNT && 'bg-navbar-active-color-primary'}
              flex items-center p-2 w-full space-x-4 hover:bg-navbar-active-color-primary hover:opacity-90
            `}
            >
              <AccountCircleIcon />
              <p>{t('sidebar.profile')}</p>
            </div>
          </NavigationComponent>
          <NavigationComponent to={appRoutes.HISTORY} onClose={onCloseSidebar}>
            <div
              className={`
              ${location.pathname === appRoutes.HISTORY && 'bg-navbar-active-color-primary'}
              flex items-center p-2 w-full space-x-4 hover:bg-navbar-active-color-primary hover:opacity-90
            `}
            >
              <ReceiptIcon />
              <p>{t('sidebar.transactionhistory')}</p>
            </div>
          </NavigationComponent>
          {/* <NavigationComponent to={appRoutes.REWARDS} onClose={onCloseSidebar}>
            <div
              className={`
              ${location.pathname === appRoutes.REWARDS && 'bg-navbar-active-color-primary'}
              flex items-center p-2 w-full space-x-4 hover:bg-navbar-active-color-primary hover:opacity-90
            `}
            >
              <RedeemIcon />
              <p>{t('sidebar.rewards')}</p>
            </div>
          </NavigationComponent> */}
          <SidebarLanguage />
        </div>
      </SlideModalComponent>
    </React.Fragment>
  );
};

export default Sidebar;
