/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';

import './App.scss'; // css
import RouterComponent from './components/organisms/RouterComponent'; // router
import useTelegram from './hooks/useTelegram.hook';
import useAuthStore from './stores/useAuthStore.store';
// import useBrandStore from './stores/useBrandStore.store';
import { useViewport } from '@tma.js/sdk-react';
import BalanceSubscription from './graphql/subscriptions/balance';
import { useAppSyncSubscription } from './hooks/useAppsyncSubscription.hook';
import { LoadingPage } from './components/atoms/Loading';
import { Helmet } from 'react-helmet';
import useCountryStore from './stores/useCountryStore.store';
// import useFetchApi from './hooks/useFetchApi.hook';
// import { GameApiService } from './services/api/player/game.api.services';
// import localStorageUtils from './utils/localStorage.utils';
// import useGameStore from './stores/useGameStore';
// import sessionStorageUtils from './utils/sessionStorage.utils';
// import useGetterApp from './hooks/useGetterApp.hook';

function App() {
  // const { brandSetting } = useBrandStore();
  const { token, isAuthenticated, user, setUpdateWalletBalance } = useAuthStore();
  const { initCountry } = useCountryStore((state) => ({ initCountry: state.initCountry }));
  // const { allGame, isAllGameStored, setAllGame } = useGameStore();
  const { loading, error } = useTelegram();
  // const allGameSessionStorage = sessionStorageUtils.get('gamedata');

  // useGetterApp();

  const viewportTelegram = useViewport();
  viewportTelegram.expand();
  // console.log(viewportTelegram.isExpanded);
  // viewportTelegram.height = 100;
  // viewportTelegram.stableHeight = 100;
  // viewportTelegram.isStable = true;

  // appsync balance
  useAppSyncSubscription({
    query: BalanceSubscription.SubscribeBalance,
    variables: { brandUsername: String(user?.brandUsername) },
    authToken: token,
    callback: (value) => {
      const valueParse = value?.data?.newBalance;
      const newWalletBalance = JSON.parse(valueParse?.walletBalance);
      setUpdateWalletBalance({
        newWalletBalance: newWalletBalance,
      });
    },
    dependencies: [isAuthenticated, token, user],
    shouldSubscribe: () => isAuthenticated && token && user, // Your custom condition
  });

  useEffect(() => {
    if (token && isAuthenticated) {
      initCountry(token);
    }
  }, [token, isAuthenticated]);

  if (error) {
    return <React.Fragment>{error}</React.Fragment>;
  }

  if (loading) {
    return <LoadingPage />;
  }

  return (
    <React.Fragment>
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no"></meta>
      </Helmet>

      {/* <head>
        <meta charset="utf-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </head> */}
      <RouterComponent />
    </React.Fragment>
  );
}

export default App;
