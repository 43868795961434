import React, { useEffect, useRef } from 'react';
import lottie from 'lottie-web';

export const LottieAnimation = ({ animationData, width, height }) => {
  const animationContainer = useRef(null);

  useEffect(() => {
    const anim = lottie.loadAnimation({
      container: animationContainer.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: animationData,
    });

    return () => anim.destroy(); // Optional clean up for unmounting
  }, [animationData]);

  return <div ref={animationContainer} style={{ width, height }} />;
};
