import { create } from 'zustand';
import api from '../services/api/api';
import brandSetting from '../data/brandSetting.json';

const useBrandStore = create((set, get) => ({
  brandId: brandSetting.brandId.toUpperCase(),
  telegramSetting: brandSetting.telegramSetting,
  setBrandId: (brandId) => set({ brandId }),
  brandSetting: brandSetting,
  setBrandSetting: async (token) => {
    const res = await api({
      url: '/getBrandSetting2',
      method: 'post',
      data: {
        brandId: get().brandId,
        type: 'dynamic',
      },
    });

    set({
      brandSetting: res,
    });
  },
}));

export default useBrandStore;
