import { create } from 'zustand';
import walletUtils from '../utils/wallet.utils';
import localStorageUtils from '../utils/localStorage.utils';

const useAuthStore = create((set, get) => ({
  user: null,
  walletBalance: new Map(),
  activeWalletBalance: null,
  token: null,
  isAuthenticated: false,
  triggerTelegramAuthenticated: false,
  getToken: () => {
    const userToken = localStorageUtils.get('userToken');

    if (userToken) {
      get().setToken(userToken);
    }
    set({ triggerTelegramAuthenticated: true });
  },
  setUser: (user) => set({ user }),
  updateUser: (updatedUser) => set({ user: { ...get().user, ...updatedUser } }),
  setWalletBalance: ({ walletBalanceList = {}, activeWallet = null }) => {
    const newWalletBalanceList = walletBalanceList ?? {};
    const walletBalanceMap = walletUtils.walletListToMap(newWalletBalanceList);
    const activeWalletBalanceMap = walletBalanceMap.get(activeWallet);
    localStorageUtils.setMap('userBalance', walletBalanceMap);

    set({
      walletBalance: walletBalanceMap,
      activeWalletBalance: activeWalletBalanceMap,
    });
  },
  setUpdateWalletBalance: ({ newWalletBalance = {} }) => {
    const walletBalanceMap = walletUtils.updateWallet(get().walletBalance, newWalletBalance);
    const activeWalletBalanceMap = walletBalanceMap.get(get().activeWalletBalance?.currency);
    set({
      walletBalance: walletBalanceMap,
      activeWalletBalance: activeWalletBalanceMap,
    });
  },

  setActiveWallet: (activeWallet) => {
    const activeWalletBalanceMap = get().walletBalance.get(activeWallet);
    set({ activeWalletBalance: activeWalletBalanceMap });
  },

  setToken: (token) => {
    localStorageUtils.set('userToken', token);
    set({ token, isAuthenticated: !!token, triggerTelegramAuthenticated: false });
  },

  logout: () => {
    localStorageUtils.clearSpesificKey();
    set({
      user: null,
      walletBalance: new Map(),
      activeWalletBalance: null,
      token: null,
      isAuthenticated: false,
      triggerTelegramAuthenticated: false,
    });
  },
}));

export default useAuthStore;
