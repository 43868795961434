import React, { lazy, Suspense } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import ScrollToTop from '../../../core/routes/scrollToTop';
import RootLayout from '../../templates/RootLayout';
import appRoutes from '../../../core/routes/routes';
import { LoadingPage } from '../../atoms/Loading';

// Lazy load each component
const HomePage = lazy(() => import('../../pages/HomePage'));
const GameCategoryPage = lazy(() => import('../../pages/GameCategoryPage'));
const NotFoundPage = lazy(() => import('../../pages/NotFoundPage'));
const AccountPage = lazy(() => import('../../pages/AccountPage'));
const PlayPage = lazy(() => import('../../pages/PlayPage'));
const WalletPage = lazy(() => import('../../pages/WalletPage'));
const HistoryPage = lazy(() => import('../../pages/HistoryPage'));
// const RewardsPage = lazy(() => import('../../pages/RewardsPage'));
const ReferralPage = lazy(() => import('../../pages/ReferralPage'));
const GameProviderPage = lazy(() => import('../../pages/GameProviderPage'));

const RouterComponent = () => {
  return (
    <>
      <BrowserRouter>
        <ScrollToTop />
        <Suspense fallback={<LoadingPage />}>
          <Routes>
            <Route element={<RootLayout />}>
              <Route path={appRoutes.HOME} element={<HomePage />} />
              <Route path={appRoutes.ACCOUNT} element={<AccountPage />} />
              <Route path={appRoutes.GAME_CATEGORY} element={<GameCategoryPage />} />
              <Route path={appRoutes.GAME_PROVIDER} element={<GameProviderPage />} />
              <Route path={appRoutes.HISTORY} element={<HistoryPage />} />
              <Route path={appRoutes.REFERRAL} element={<ReferralPage />} />
              {/* <Route path={appRoutes.REWARDS} element={<RewardsPage />} /> */}
              <Route path={appRoutes.PLAY} element={<PlayPage />} />
              <Route path={appRoutes.WALLET} element={<WalletPage />} />
              <Route path={appRoutes.UNKNOWN} element={<NotFoundPage />} />
            </Route>
          </Routes>
        </Suspense>
      </BrowserRouter>
    </>
  );
};

export default RouterComponent;

// import React from 'react';
// import { BrowserRouter } from 'react-router-dom';
// import RouteRenderer from '../../../core/routes/routeRenderer';
// import ScrollToTop from '../../../core/routes/scrollToTop';

// const RouterComponent = () => {
//   return (
//     <React.Fragment>
//       <BrowserRouter>
//         <ScrollToTop />
//         <RouteRenderer />
//       </BrowserRouter>
//     </React.Fragment>
//   );
// };

// export default RouterComponent;
