/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect } from 'react';
import useApiRequest from './useApiRequest.hook';
import useAuthStore from '../stores/useAuthStore.store';
import brandSetting from '../data/brandSetting.json';

function useAuth() {
  const {
    data: authData,
    error,
    loading,
    post: authPost,
  } = useApiRequest(`/telegrambot/auth/${brandSetting.brandId.toLowerCase()}/1`);
  const { data: userData, post: userFetch } = useApiRequest('/me');
  const { data: userBalance, post: balanceFetch } = useApiRequest('/get-balance2');

  const { token, setUser, setToken, setWalletBalance, isAuthenticated, logout } = useAuthStore();

  useEffect(() => {
    if (isAuthenticated && token) {
      userFetch({ options: { headers: { Authorization: token } } });
      balanceFetch({
        options: { headers: { Authorization: token } },
        data: { type: 'all' },
      });
    }
  }, [isAuthenticated, token]);

  useEffect(() => {
    console.log('authData : ', authData);
    if (authData?.data?.loginToken) {
      setToken(authData.data.loginToken);
      // Fetch user data and balance once logged in
      userFetch({
        options: { headers: { Authorization: authData.data.loginToken } },
      });
      balanceFetch({
        options: { headers: { Authorization: authData.data.loginToken } },
        data: { type: 'all' },
      });
    } else if (authData?.user) {
      setUser(authData.user);
    } else if (error) {
      console.error('Error:', error);
    }
  }, [authData, error]);

  useEffect(() => {
    if (userBalance) {
      const walletData = userBalance?.data ?? {};
      const walletBalance = walletData?.walletBalance ?? {};
      const walletActive = walletData?.activeWallet ?? null;
      // walletBalance, walletActive
      setWalletBalance({
        walletBalanceList: walletBalance,
        activeWallet: walletActive,
      });
    }
  }, [userBalance]);

  useEffect(() => {
    if (userData) {
      // change it when change the response
      delete userData?.error;
      delete userData?.message;
      setUser(userData);
    }
  }, [userData]);

  const loginUser = useCallback(
    async (credentials) => {
      await authPost({
        options: {
          headers: { Authorization: `twa-init-data ${credentials}` },
        },
      });
    },
    [authPost]
  );

  const logoutUser = useCallback(() => {
    logout();
  }, [logout]);

  const getUser = () => {
    userFetch({
      options: { headers: { Authorization: authData.data.loginToken } },
    });
    balanceFetch({
      options: { headers: { Authorization: authData.data.loginToken } },
      data: { type: 'all' },
    });
  };

  return {
    loginUser,
    logoutUser,
    getUser,
    loading,
    error,
    // ... other auth-related functions
  };
}

export default useAuth;
