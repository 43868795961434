import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpBackend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

const supportedLngs = [
  'en-GB',
  'es-ES',
  'fr-FR',
  // 'hi-IN',
  'id-ID',
  'it-IT',
  'ja-JP',
  'ko-KR',
  // 'ms-MY',
  'pt-PT',
  'ru-RU',
  // 'th-TH',
  'tr-TR',
  // 'vi-VN',
  'zh-CN',
];

i18n
  .use(HttpBackend) // Fetch translations from a server
  .use(LanguageDetector) // Detects user language
  .use(initReactI18next) // Initialize react-i18next
  .init({
    // lng: 'en-GB',
    supportedLngs,
    fallbackLng: 'en-GB',
    // ns: ['translation'],
    // defaultNS: 'translation',
    debug: true,
    load: 'currentOnly',
    backend: {
      loadPath: '/locales/{{lng}}.json', // ensure the path is correct
    },
    detection: {
      order: ['querystring', 'cookie', 'localStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
      caches: ['localStorage', 'cookie'],
    },
  });

export default i18n;
