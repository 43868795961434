const walletListToMap = (balance) => {
  const balanceMap = new Map();

  for (const item in balance) {
    balanceMap.set(item, balance[item]);
  }

  return balanceMap;
};

const updateWallet = (existWallet, newWallet) => {
  if (existWallet && newWallet) {
    const existBalance = new Map(existWallet);
    let newBalance = newWallet;

    for (const item in newBalance) {
      const prevData = existBalance.get(item);

      if (prevData && typeof prevData === 'object') {
        existBalance.set(item, { ...prevData, totalBalance: newBalance[item] });
      }
    }

    return existBalance;
  }
};

// const formatCurrency = (num) => {
//   // Round to a maximum of 6 decimal places
//   num = Math.round(num * 1000000) / 1000000;

//   // Split the number into its integer and decimal parts
//   let [intPart, decPart] = num.toString().split('.');

//   // Add a period (.) every 3 digits in the integer part
//   intPart = intPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

//   // Return the formatted number, or just the integer part if no decimal
//   return decPart ? `${intPart}.${decPart}` : intPart;
// };

// const formatCurrency = (num, maxDecimalPlaces = 5) => {
//   // Round to the specified number of decimal places
//   const factor = Math.pow(10, maxDecimalPlaces);
//   num = Math.round(num * factor) / factor;

//   // Split the number into its integer and decimal parts
//   let [intPart, decPart] = num.toString().split('.');

//   // Add a period (.) every 3 digits in the integer part
//   intPart = intPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

//   // If there is a decimal part, truncate it to the max number of decimal places
//   if (decPart) {
//     decPart = decPart.substring(0, maxDecimalPlaces);
//   }

//   // Return the formatted number, or just the integer part if no decimal
//   return decPart ? `${intPart}.${decPart}` : intPart;
// };

const formatCurrency = (num, maxDecimalPlaces = 5) => {
  // Convert input to a number in case it's not already.
  num = Number(num);
  if (isNaN(num)) return NaN; // Return NaN if the input is not a valid number.

  // Truncate to the specified number of decimal places without rounding.
  const factor = Math.pow(10, maxDecimalPlaces);
  num = num >= 0 ? Math.floor(num * factor) / factor : Math.ceil(num * factor) / factor;

  // Convert the number to a string to split into integer and decimal parts.
  let [intPart, decPart] = num.toString().split('.');

  // Add a comma every 3 digits in the integer part from the end.
  intPart = intPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  // Ensure the decimal part is not longer than the maximum allowed decimal places.
  if (decPart && decPart.length > maxDecimalPlaces) {
    decPart = decPart.substring(0, maxDecimalPlaces);
  }

  // Return the formatted number, or just the integer part if no decimal part exists.
  return decPart ? `${intPart}.${decPart}` : intPart;
};

const parseFloatCustom = (input) => {
  // Parse the input to a float.
  const num = parseFloat(input);
  if (isNaN(num)) return NaN; // Return NaN if the input is not a valid number.

  // Convert the number to a string, then split it at the decimal point.
  const parts = num.toString().split('.');

  // Check if the number has a decimal part.
  if (parts.length > 1) {
    // Limit the decimal part to a maximum of 5 digits without rounding.
    parts[1] = parts[1].substring(0, 5);
  }

  // Join the parts back into a full string and convert it to a float.
  const truncated = parseFloat(parts.join('.'));

  return truncated;
};

const walletUtils = { walletListToMap, updateWallet, formatCurrency, parseFloatCustom };

export default walletUtils;
