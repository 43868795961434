import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const NavigationComponent = ({ to, onClose, children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const onCloseHandler = () => {
    if (to && location.pathname !== to) {
      navigate(to);
    }
    if (onClose) {
      onClose();
    }
  };

  return (
    <React.Fragment>
      <div className="w-full h-full cursor-pointer" onClick={onCloseHandler}>
        {children}
      </div>
      {/* <Link to={to} onClick={onCloseHandler}></Link> */}
    </React.Fragment>
  );
};

export default NavigationComponent;
